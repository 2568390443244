<template>
  <div class="not-found">
    <div class="image-container">
<img src="../assets/images/Brynton.jpg" alt="Not Found" />  
        <div style="font-family: 'Montserrat', sans-serif; word-spacing: 1px; color:black; font-size: 30px; font-weight: bold;" >
          <a href="#">Call 714-993-4800</a> for further assistance.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
}
</script>

<style scoped>
.not-found {
  text-align: center;
}

.image-container {
  position: relative;
  display: inline-block;
}

.image-container img {
  width: 700px; 
  height: auto; 
  max-width: 100%; 
}

.overlay {
    position: absolute;
    top: 106%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
    text-align: center;
    /* background: rgba(0, 0, 0, 0.5); */
    padding: 10px;
    font-weight: 160px;
    font-size: 24px;
}

.overlay a {
  color: #ffcc00; 
  text-decoration: underline; 
}
</style>
