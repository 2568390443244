<template>
  <div id="app">
    <div class="overflow-hidden min-h-screen flex flex-col">
    <HeaderView />
    <FooterView />
    </div>
  </div>
</template>

<script>
import HeaderView from './views/HeaderView.vue'
import FooterView from './views/FooterView.vue'


export default {
  name: 'App',
  components: {
    HeaderView,
    FooterView
  },
}
</script>

<style>
#app {
  font-family: 'Open Sans', sans-serif;
  color: #333;
  margin: 0;
  padding: 0;
}

    .box-accent[data-v-7b32b693] {
        --box-accent-skew: skewX(-45deg) skewY(5deg);
        --box-accent-rotation: rotate(-10deg);
        --box-accent-translation: translate(0, 0);
        --box-accent-inversion: scaleX(1);
        position: absolute;
        z-index: 0;
        width: 13%;
        height: 0;
        padding-bottom: 6%
    }

    .box-accent[data-v-7cb7b147] {
        display: block !important;
    }

    .box-accent--top-right[data-v-7b32b693] {
        --box-accent-translation: translate(70%, -50%);
        top: 0;
        right: 0
    }

    .box-accent--bottom-left[data-v-7b32b693] {
        --box-accent-translation: translate(-90%, 15%);
        --box-accent-rotation: rotate(10deg);
        bottom: 0;
        left: 0
    }

    .box-accent--inverted[data-v-7b32b693] {
        --box-accent-inversion: scaleX(-1) scaleY(-1)
    }

    .box-accent__element[data-v-7b32b693] {
        width: 100%;
        height: 100%;
        position: absolute
    }

    .box-accent__element--large[data-v-7b32b693] {
        --path: polygon(0 85%, 0 0, 60% 0)
    }

    .box-accent__element--small[data-v-7b32b693] {
        --path: polygon(15% 100%, 90% 100%, 50% 60%)
    }

    .box-accent__element[data-v-7b32b693] {
        clip-path: var(--path);
        -webkit-clip-path: var(--path)
    }

    @media (min-width:640px) {
        .has-file[data-v-7b32b693] {
            padding-top: 0;
            padding-bottom: 3rem
        }
    }

    .box-accent[data-v-7b32b693] {
        transform: var(--box-accent-translation) var(--box-accent-rotation) var(--box-accent-skew) var(--box-accent-inversion)
    }

    .easter-egg {
        transition: all .2s ease-in-out;
        height: max-content;
        top: calc(8.5rem + 154px);
        left: 20%;
        font-size: .5rem;
        transform-origin: top;
        writing-mode: vertical-lr;
        -webkit-text-orientation: upright;
        text-orientation: upright;
        letter-spacing: 7.57118px;
        position: fixed;
        padding-left: 1rem;
        padding-right: 1rem;
        text-transform: uppercase;
        box-sizing: content-box;
        overflow: visible;
        --text-opacity: 1;
        color: #a0aec0;
        color: rgba(160,174,192,var(--text-opacity));
        font-weight: 600
    }

        .easter-egg:hover {
            letter-spacing: 12.6186px;
            --text-opacity: 1;
            color: #000;
            color: rgba(0,0,0,var(--text-opacity))
        }

    .easter-egg__channel {
        transform: scale(1);
        position: fixed;
        height: 100%;
        z-index: 10;
        right: 0
    }


    .banner__logo[data-v-4d760c33] {
        margin: 0px !important;
    }



    @media (min-width:1280px) {
        .flex-container.flex-container--two-col > [data-v-1208f910] {
            width: 50%
        }
    }

    .box-accent {
        display: block !important;
    }

    @media screen and (max-width: 650px) {
        .banner__spacer.hidden {
            display: block !important;
        }

        .page-container {
            width: calc(100% - 30px);
        }

        #nav-login ul {
            padding: 0;
        }

        .banner__spacer.hidden .bolding-link {
            font-size:;
            font-size: 13px !important;
        }

        .hero__inner.w-full {
            display: flex;
            flex-direction: column;
            width: 100% !important;
        }

        img.hero__img {
            display: block !important;
            margin-bottom: 15px;
            margin-top: 15px;
        }

        .banner__spacer.hidden .page-container {
            padding: 10px 6px;
        }
    }
</style>