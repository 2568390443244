
import axios from "axios";

//const baseCommonURL = 'https://localhost:7226/api';  
const baseCommonURL = 'https://node.brytonprinting.com/api';  

const api = axios.create({
    baseURL: baseCommonURL
});

class AdminService {
    
    async GetEmailValidation() {
        // eslint-disable-next-line
     //   debugger;
         return await api.get(`ContactForm/GetEmailValidation`);
     }
     fetchUserData(formData) {
        // eslint-disable-next-line
     //   debugger;
        return api.post('ContactForm/GetContact', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      }
      uploadForm(model) {
        return axios.post('ContactForm/PostContactForm', model)
      }
      handleLogin(loginModel) {
        // eslint-disable-next-line
       // debugger;
        return api.post('Login/loginRequest', loginModel)
      }
      handleSubmit(formData) {
        // eslint-disable-next-line
     //   debugger;
        
        return api.post('ContactForm/SendContact', formData,{
        headers: {
            'Content-Type': 'multipart/form-data',
        }
          })
    }
    async Getpagedata(formData) {
        // eslint-disable-next-line
       // debugger;
        return await api.post('ContactForm/GetAllPagesList', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }
    async EditPages(Id) {
        // eslint-disable-next-line
        //   debugger;
        return await api.get(`ContactForm/GetPagesEditDataById/${Id}`);
    }
   async AddFormdata(model) {
        // eslint-disable-next-line
      //  debugger;
       return await api.post('ContactForm/AddPages', model, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }
    async DeletePagesById(Id) {
        // eslint-disable-next-line
      //  debugger;
        return await api.get(`ContactForm/DeletePageById/${Id}`);
    }
    async GetdynamicMetadata(model) {
        return await api.post(`ContactForm/GetDynamicMetadata`, model);
    }
    async getPageContent(pageURL) {
        return await api.get(`ContactForm/GetPagesContant/${pageURL}`);
    }
    async GetDynamicMenu() {
        // eslint-disable-next-line
     //   debugger;
        return await api.get(`ContactForm/GetDynamicMenu`);
    }
    async DownloadFile(folderName, filerName) {
        return await api.get(`ContactForm/DownloadFile/${folderName}/${filerName}`, { responseType: 'blob' });
    }
}

export default new AdminService();
