import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/images/Style.css'
import 'tinymce/tinymce';
import 'tinymce/themes/silver/theme';
import 'tinymce/icons/default/icons';
//import CKEditor from '@mayasabha/ckeditor4-vue3';
const app = createApp(App).use(router)
app.mount('#app')
app.config.globalProperties.$TNYCkEditorKey = 'm6fo4y8vekzixco1g2zczpiyxl9q69tcv74qyjfggmwl8fgj';

