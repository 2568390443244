import { createRouter, createWebHistory } from 'vue-router'
import Contact from '../views/ContactView.vue'
//import PrintingServices from '../views/PrintingServices.vue'
//import EnviormentView from '@/views/EnviormentView.vue'
// import UploadFile from '@/views/UploadFile.vue'
import UploadFileThankYou from '@/views/UploadFileThankYou.vue'
import ThankYou from '@/views/ThankYou.vue'
import FileList from '@/views/FileListing.vue'
import Login from '@/views/LogIn.vue'
import Index from '@/views/IndexView.vue'
import DummySider from '../components/DummySider.vue'
import SlugPages from '@/views/SlugPages.vue'
import PagesList from '@/views/PagesList.vue'
import NotFound from '@/views/NotFound.vue'
/*import DynamicPages from '@/views/DynamicPages.vue'*/
import AdminService from '../Services/AdminService'
const routes = [

    {
        path: '/',
        name: 'Index',
        component: Index
    },
    //{
    //    path: '/PrintingServices',
    //    name: 'PrintingServices',
    //    component: PrintingServices
    //},

    //{
    //    path: '/Enviorment',
    //    name: 'Enviorment',
    //    component: EnviormentView
    //},
    {
        path: '/Login',
        name: 'Login',
        component: Login
    },

    {
        path: '/Contact',
        name: 'Contact',
        component: Contact
    },
    // {
    //   path: '/UploadFile', 
    //   name: 'UploadFile',
    //   component: UploadFile
    // },
    {
        path: '/Upload/ThankYou',
        name: 'UploadFileThankYou',
        component: UploadFileThankYou
    },
    {
        path: '/Contact/Thankyou',
        name: 'Thankyou',
        component: ThankYou
    },
    {
        path: '/UploadFileListing',
        name: 'FileList',
        component: FileList
    },
    {
        path: '/DummySider',
        name: 'DummySider',
        component: DummySider
    },
    {
        path: '/Pages',
        name: 'Pages',
        component: SlugPages
    },
    {
        path: '/PagesList',
        name: 'PagesList',
        component: PagesList
    },
    {
        path: '/Pages/:Id',
        name: 'Pages | Add/Edit Pages',
        component: SlugPages

    },
    {
        path: '/NotFound',
        name: 'NotFound',
        component: NotFound

    },
    //{
    //    path: '/ContactUs',
    //    name: 'ContactUs',
    //    component: () => import('@/views/DynamicPages.vue')

    //},
    //{
    //    path: '/AboutUs',
    //    name: 'AboutUs',
    //    component: () => import('@/views/DynamicPages.vue')

    //},
    //{
    //    path: '/JoinUs',
    //    name: 'JoinUs',
    //    component: () => import('@/views/DynamicPages.vue')

    //},
    //{
    //    path: '/Find',
    //    name: 'Find',
    //    component: () => import('@/views/DynamicPages.vue')

    //},
    //{
    //    path: '/Activity',
    //    name: 'Activity',
    //    component: () => import('@/views/DynamicPages.vue')

    //},
    {
        path: '/:url',
        name: 'dynamicPage',
        component: () => import('@/views/DynamicPages.vue')
    },
    {
        path: '/UploadFiles',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFound
    }
]
let HeaderNavlinks = [];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes, HeaderNavlinks
})


async function GetmetaData(to) {
    // eslint-disable-next-line
   // debugger;
    let metaModel = {
        PageTitle: to.name,
        PageUrl: to.href.replace('/', ''),
        //companyId: localStorage.getItem('companyId')
    }
    const response = await AdminService.GetdynamicMetadata(metaModel);
    const metaKeyword = response.data?.MetaKeyword || ''; // Provide fallback
    const metaDescription = response.data?.MetaDescription || '';
    const metaAuthor = response.data?.MetaTitle || '';
    // Set the dynamic meta tags in the HTML
    document.getElementById('keyword').setAttribute("content", metaKeyword);
    document.getElementById('description').setAttribute("content", metaDescription);
    document.getElementById('title').setAttribute("content", metaAuthor);

    document.title = `BrytonPrinting | ${metaAuthor}`;

    var screen = window.outerWidth;
    if (screen > 1080) {
        document.getElementById('viewport').setAttribute("content", "width=device-width,initial-scale=1")
    } else {
        document.getElementById('viewport').setAttribute("content", "width=device-width,initial-scale=1, user-scalable='no'")
    }
}

router.beforeEach((to, from, next) => {
    GetmetaData(to)
    next()
})

function createMenuItems(menuList) {
    // eslint-disable-next-line
   // debugger;
    console.log("get menu name list", menuList);
    return menuList.map(item => { 
        const matchingRoute = routes.find(route => route.name === item.menuname);
        console.log("get menu name111", matchingRoute);

        return {
            menuname: matchingRoute ? item.menuname : 'dynamicPage',
            displayText: item.menuname,
            //childMenu: item.ChildMenu,
            //params: matchingRoute ? null : { url: item.menuname }
            params:{url: item.routeUrl }
            
        };
    })
}
async function setMenuItems() {
    // eslint-disable-next-line
  //  debugger;
    const response = await AdminService.GetDynamicMenu();
    HeaderNavlinks.push((createMenuItems(response.data)));

//    console.log('menu list', HeaderFooterList.HeaderMenuListing);
}



router.beforeEach(async (to, from, next) => {
    // eslint-disable-next-line
   // debugger;
    const specialRoutes = 'Home'// ['errorPage', 'logoutRedirect'];
    if (specialRoutes.includes(to.name)) {
        return next();
    }


    try {

        await setMenuItems();
        
     /*   await beforeRouteHitMethods(to);*/
        //if (to.hash == '' || to.hash == null) {
        //    window.scrollTo(0, 0);
        //}
        await GetmetaData(to);
        return next();

    }
    catch (error) {
        console.error(error);
        return next('/errorPage');
    }
})
export default router
