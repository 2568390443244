<template>
    <div class="easter-egg__channel hidden desktop:block" style="width: calc(var(--container-padding-x) + 193px - 75px)">
        <div class="easter-egg">
            small details matter to us

        </div>
    </div>
    <main class="flex-container flex-container--two-col page-container page-container--padded pb-8 desktop:pb-16 flex-grow"
          data-v-1208f910>
        <!---->
        <div class="call-cta items-center" data-v-1208f910>
            <div class="img-container"
                 style="--img-width:267px;--img-height:400px;--img-mobile-width:201px;--img-mobile-height:302px" data-v-1208f910>
                <img src="../assets/images/c2741c.jpg" width="267" height="400"
                     alt="A helpful receptionist wears a headset and gives a friendly smile">
            </div>
            <a href="tel:+1-714-993-4800" class="callout uppercase cursor-pointer text-2xl tablet:text-3xl font-black"
               style="--text-color:#000;--reactive-text-color:#000" data-v-8e38860e data-v-1208f910>
                <div class="animated-underline under--flex animated-underline--left"
                     style="--underline-color:var(--bryton-yellow-500);--default-underline-width:33%;--tablet-underline-width:33%;--desktop-underline-width:33%;--default-underline-alignment:left;--tablet-underline-alignment:left;--desktop-underline-alignment:left;--default-alignment:flex-start;--tablet-alignment:flex-start;--desktop-alignment:flex-start"
                     data-v-52d451c5 data-v-8e38860e>
                    <div class="text-center font-bold text-xl tablet:text-3xl" data-v-52d451c5 data-v-1208f910>

                        <span class="contact-btn-text">We're Ready. Let's Talk.</span>
                        <br data-v-52d451c5 data-v-1208f910> <span class=" contact-btn-text text-5xl tablet:text-6xl whitespace-no-wrap" data-v-52d451c5 data-v-1208f910>
                            714-993-4800

                        </span>
                    </div>
                </div>
            </a>
        </div>
        <div data-v-1208f910>
            <div class="max-w-lg content-between tablet:px-4 text-lg contact-info" data-v-1208f910>
                <div data-v-1208f910>
                    <span class="contact-info__heading" data-v-1208f910>Office Address:</span><br data-v-1208f910>
                    3345 E Miraloma Ave Unit 134<br data-v-1208f910>
                    Anaheim,
                    CA
                    92806
                </div>
                <div data-v-1208f910>
                    <span class="contact-info__heading" data-v-1208f910>Owner:</span>
                    Jeff Weston
                </div>
                <div data-v-1208f910>
                    <span class="contact-info__heading" data-v-1208f910>Office:</span> <a href="tel:+1-714-993-4800" class="contact-info__link" data-v-1208f910>
                        714-993-4800
                    </a>
                </div>
                <div data-v-1208f910>
                    <span class="contact-info__heading" data-v-1208f910>Email:</span> <a href="mailto:Jeff@BrytonPrinting.com" class="contact-info__link" data-v-1208f910>
                        Jeff@BrytonPrinting.com
                    </a>
                </div>
                <div data-v-1208f910>
                    <a rel="noopener" target="_blank"
                       href="../external.html?link=https://www.google.com/maps/place/Bryton+Printing/@33.861183,-117.85112,16z/data=!4m5!3m4!1s0x0:0xd6287fcb4956d260!8m2!3d33.8611828!4d-117.8511197?hl=en"
                       class="callout uppercase cursor-pointer text-2xl tablet:text-3xl font-black"
                       style="--text-color:#000;--reactive-text-color:#000" data-v-8e38860e data-v-1208f910>
                        <div class="animated-underline animated-underline--left"
                             style="--underline-color:var(--bryton-yellow-500);--default-underline-width:33%;--tablet-underline-width:33%;--desktop-underline-width:33%;--default-underline-alignment:left;--tablet-underline-alignment:left;--desktop-underline-alignment:left;--default-alignment:flex-start;--tablet-alignment:flex-start;--desktop-alignment:flex-start"
                             data-v-52d451c5 data-v-8e38860e>
                            <span class="text-xl" data-v-52d451c5 data-v-1208f910>
                                find us on google
                                maps
                            </span>
                        </div>
                    </a>
                </div>
                <div class="contact-info__heading" data-v-1208f910>
                    We are here to serve you Monday through Friday.
                    Office hours are from 8am to 6pm.
                </div>
            </div>
        </div>
        <div class="w-full tablet:px-8" data-v-1dec9371 data-v-1208f910>
            <div class="text-center flex flex-col place-items-center place-content-center w-full"
                 style="height:512px;display:none" data-v-1dec9371>
                <div class="text-4xl text-bryton-green-500 font-black uppercase" data-v-1dec9371>
                    Thanks!
                </div>
                <div class="text-lg max-w-sm" data-v-1dec9371>
                    We got your message. Please keep an eye on your inbox for our response.
                </div>
            </div>
            <div class="text-center flex flex-col place-items-center place-content-center w-full"
                 style="height:512px;display:none" data-v-1dec9371>
                <picture class="max-w-lg animate-working" data-v-1dec9371>
                    <img src="_../assets/images/6304f0.png" width="96" height="96">
                </picture>
                <div class="text-2xl font-black uppercase" data-v-1dec9371>
                    Sending...
                </div>
            </div>
            <div class="text-center flex flex-col place-items-center place-content-center w-full"
                 style="height:512px;display:none" data-v-1dec9371>
                <div class="text-4xl text-bryton-red-500 font-black uppercase" data-v-1dec9371>
                    Oops!
                </div>
                <div class="text-lg max-w-sm" data-v-1dec9371>
                    Looks like we're having trouble right now. Please try again later or...
                </div>
                <a href="mailto:Jeff@BrytonPrinting.com"
                   class="callout uppercase cursor-pointer text-2xl tablet:text-3xl font-black text-center flex mx-auto mt-6"
                   style="--text-color:#000;--reactive-text-color:#000" data-v-8e38860e data-v-1dec9371>
                    <div class="animated-underline"
                         style="--underline-color:var(--bryton-yellow-500);--default-underline-width:33%;--tablet-underline-width:33%;--desktop-underline-width:33%;--default-underline-alignment:center;--tablet-underline-alignment:center;--desktop-underline-alignment:center;--default-alignment:center;--tablet-alignment:center;--desktop-alignment:center"
                         data-v-52d451c5 data-v-8e38860e>
                        E-Mail Us Instead
                    </div>
                </a>
            </div>
            <div class="contactform" data-v-1dec9371>
                <div data-v-1dec9371></div>
                <form action="../external.html?link=https://crm.zoho.com/crm/WebToLeadForm" name="WebToLeads4695435000001702036"
                      method="POST" accept-charset="UTF-8" class="contact-form" data-v-1dec9371>
                    <input name="xnQsjsdp" value="06f81ed146aa1570969f28a5f116b5fc1bac804c0f5469a562893bbc599dd081"
                           style="display:none" data-v-1dec9371>
                    <input id="zc_gad" type="hidden" name="zc_gad" data-v-1dec9371> <input name="xmIwtLD"
                                                                                           value="917746a04f8ebd0082d29a6fa9f219c1e12b93738752c89db0a079af15c6406d" style="display:none"
                                                                                           data-v-1dec9371>
                    <input name="actionType" value="TGVhZHM=" style="display:none" data-v-1dec9371> <input name="returnURL"
                                                                                                           value="https://www.brytonprinting.com" style="display:none" data-v-1dec9371> <input name="Lead Source"
                                                                                                                                                                                               value="Bryton Printing Website" style="display:none" data-v-1dec9371>
                    <div class="text-input bg-bryton-grey-500 px-4 py-3 text-sm border border-gray-600 tablet:border-gray-300 hover:border-gray-600 text-input--required"
                         data-v-8cd163c6 data-v-1dec9371>
                        <label for="First_Name" class="sr-only" data-v-8cd163c6>
                            FIRST NAME
                        </label> <input id="First_Name" v-model="formData.firstName" placeholder="FIRST NAME"
                                        autocomplete="given-name" name="First Name" maxlength="40" required
                                        class="text-input__field placeholder-gray-500 outline-none bg-transparent w-full" data-v-8cd163c6>
                        <span v-if="errors.firstName" class="text-danger">{{ errors.firstName }}</span>
                    </div>
                    <div class="text-input bg-bryton-grey-500 px-4 py-3 text-sm border border-gray-600 tablet:border-gray-300 hover:border-gray-600 text-input--required"
                         data-v-8cd163c6 data-v-1dec9371>
                        <label for="Last_Name" class="sr-only" data-v-8cd163c6>
                            LAST NAME
                        </label> <input id="Last_Name" v-model="formData.lastName" placeholder="LAST NAME"
                                        autocomplete="family-name" name="Last Name" maxlength="80" required
                                        class="text-input__field placeholder-gray-500 outline-none bg-transparent w-full" data-v-8cd163c6>
                        <span v-if="errors.lastName" class="text-danger">{{ errors.lastName }}</span>
                    </div>
                    <div class="text-input bg-bryton-grey-500 px-4 py-3 text-sm border border-gray-600 tablet:border-gray-300 hover:border-gray-600 text-input--required"
                         data-v-8cd163c6 data-v-1dec9371>
                        <label for="Company" class="sr-only" data-v-8cd163c6>
                            BUSINESS NAME
                        </label> <input id="Company" v-model="formData.businessName" placeholder="BUSINESS NAME"
                                        autocomplete="organization" name="Company" maxlength="100" required
                                        class="text-input__field placeholder-gray-500 outline-none bg-transparent w-full" data-v-8cd163c6>
                        <span v-if="errors.businessName" class="text-danger">{{ errors.businessName }}</span>
                    </div>
                    <div class="text-input bg-bryton-grey-500 px-4 py-3 text-sm border border-gray-600 tablet:border-gray-300 hover:border-gray-600 text-input--required"
                         data-v-8cd163c6 data-v-1dec9371>
                        <label for="Email" class="sr-only" data-v-8cd163c6>
                            EMAIL
                        </label>
                        <input id="Email" v-model="formData.email" placeholder="EMAIL" type="email" autocomplete="email"
                               name="Email" maxlength="100" required
                               class="text-input__field placeholder-gray-500 outline-none bg-transparent w-full" data-v-8cd163c6>
                        <span v-if="errors.email" class="text-danger">{{ errors.email }}</span>
                    </div>
                    <div class="text-area box-content bg-bryton-grey-500 pl-4 pt-3 text-sm border border-gray-600 tablet:border-gray-300 hover:border-gray-600 contact-form__field--tall col-span-full text-area--required"
                         data-v-082907f1 data-v-1dec9371>
                        <label for="Description" class="sr-only" data-v-082907f1>
                            Message
                        </label> <textarea id="Description" v-model="formData.message" placeholder="HOW CAN WE HELP?"
                                           name="LEADCF12" max-length="1024" required
                                           class="text-area__field placeholder-gray-500 outline-none bg-transparent w-full"
                                           data-v-082907f1></textarea>
                        <span v-if="errors.message" class="text-danger">{{ errors.message }}</span>
                    </div>
                    <div class="col-sm-6 col-xs-6">
                        <div class="form-group mb-3" :class="{ '': !isCapchaValid }">
                            <div id="recaptcha-ReviewForm" class="g-recaptcha" :data-sitekey="siteKey"></div>
                        </div>
                        <span v-if="errors.captcha" class="text-danger">{{ errors.captcha }}</span>

                    </div>
                    <div class="flex flex-row col-span-full" data-v-1dec9371>
                        <div class="text-bryton-red-500" style="display:none" data-v-1dec9371>
                            Sorry, something went wrong. Please try again.
                        </div>
                        <button @click="handleSubmit" type="button"
                                class="callout uppercase cursor-pointer text-2xl tablet:text-3xl font-black ml-auto font-extrabold text-base leading-none"
                                style="--text-color:#000;--reactive-text-color:#000" data-v-8e38860e data-v-1dec9371>
                            <div class="animated-underline animated-underline--right"
                                 style="--underline-color:var(--bryton-yellow-500);--default-underline-width:33%;--tablet-underline-width:33%;--desktop-underline-width:33%;--default-underline-alignment:right;--tablet-underline-alignment:right;--desktop-underline-alignment:right;--default-alignment:flex-end;--tablet-alignment:flex-end;--desktop-alignment:flex-end"
                                 data-v-52d451c5 data-v-8e38860e>
                                SEND
                            </div>
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3313.119684876835!2d-117.85302088423616!3d33.860806280658366!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dcd6bec20ebb37%3A0xd6287fcb4956d260!2sBryton%20Printing!5e0!3m2!1sen!2sus!4v1606582735592!5m2!1sen!2sus"
                title="Google Maps Directions to Our Office" allowfullscreen="" aria-hidden="false" tabindex="0"
                class="tablet:px-4" style="border:0" data-v-1208f910="" width="800" height="430"></iframe>

    </main>
</template>


<script>
import axios from 'axios';
import AdminService from '@/Services/AdminService';


export default {
  data() {
    return {
      siteKey: '6LfPmGQnAAAAAKF_W3hIf1iiQ33tbLRw4qmKYdBO',
      isCapchaValid: false,
      captchaTimeout: null,
      formData: {
        firstName: '',
        lastName: '',
        businessName: '',
        message: '',
        email: ''
      },
      selectedFiles: [],
      errors: {
        email: '',
        firstName: '',
        lastName: '',
        businessName: '',
        message: ''

      },
      progress: 0,
      isEmailValidate: false
    };
  },
  async created() {
    await this.GetEmailValidation();
    this.renderRecaptcha();
  },
  //   mounted() {
  //     // Load ReCAPTCHA script
  //     this.loadRecaptchaScript();
  //   },

  mounted() {
    // Ensure this function is only called once
    if (!window.recaptchaRendered) {
      this.renderRecaptcha();
      window.recaptchaRendered = true;
      this.loadRecaptchaScript();
    }
  },
  computed: {
    progressBarWidth() {
      return `${this.progress}%`;
    }
  },
  methods: {
    // async handleSubmit() {
    //   // eslint-disable-next-line
    //   debugger;
    //   this.sending = true;
    //   this.error = false;
    //   this.errorMessage = '';
    //   try {
    //     const response = await axios.post('https://node.brytonprinting.com/api/ContactForm/SendContact', this.formData,{

    //     headers: {
    //       'Content-Type': 'multipart/form-data'
    //     },
    //   })
    //     console.log(response);
    //     this.$router.push('/Contact/Thankyou');
    //     this.formSubmitted = true;
    //     console.log('Form submitted successfully:', this.formData);
    //   } catch (err) {
    //     this.error = true;
    //     this.errorMessage = err.message;
    //     console.error('Error submitting form:', err);
    //   } finally {
    //     this.sending = false;
    //   }
    // },

    async handleSubmit() {
      // eslint-disable-next-line
      debugger;
      this.sending = true;
      this.error = false;
      this.errorMessage = '';
      let isFormValid = this.validateForm();
      if (isFormValid) {
        try {
          AdminService.handleSubmit(this.formData).then((response) => {
            console.log(response);
            this.$router.push('/Contact/Thankyou');
            this.formSubmitted = true;
            console.log('Form submitted successfully:', this.formData);
          })
        } catch (err) {
          this.error = true;
          this.errorMessage = err.message;
          console.error('Error submitting form:', err);
        } finally {
          this.sending = false;
        }
      }
    },
    loadRecaptchaScript() {
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?onload=onRecaptchaLoad&render=explicit`;
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);

      // Define a global callback function
      window.onRecaptchaLoad = () => {
        this.renderRecaptcha();
      };
    },
    renderRecaptcha() {
      if (window.grecaptcha) {
        const existingWidgetId = document.getElementById('recaptcha-ReviewForm').getAttribute('data-widget-id');

        // Check if reCAPTCHA has already been rendered
        if (existingWidgetId) {
          window.grecaptcha.reset(existingWidgetId); // Reset the existing reCAPTCHA
        } else {
          const widgetId = window.grecaptcha.render('recaptcha-ReviewForm', {
            sitekey: this.siteKey,
            callback: this.onRecaptchaSuccess,
            'expired-callback': this.onRecaptchaExpired,
          });

          // Store the widget ID in the element for later reference
          document.getElementById('recaptcha-ReviewForm').setAttribute('data-widget-id', widgetId);
        }
      }
    },
    onCaptchaSuccess() {
      // When CAPTCHA is successfully completed
      this.captchaValid = true;

      // Clear any existing timeout
      if (this.captchaTimeout) {
        clearTimeout(this.captchaTimeout);
      }

      // Set a timeout to expire the CAPTCHA after a certain period
      this.captchaTimeout = setTimeout(() => {
        this.captchaValid = false;
        alert('CAPTCHA expired, please complete it again.');
      }, 5 * 60 * 1000); // 5 minutes timeout
    },
    onCaptchaExpire() {
      this.captchaValid = false;
    },
    beforeDestroy() {
      // Clear the timeout when the component is destroyed
      if (this.captchaTimeout) {
        clearTimeout(this.captchaTimeout);
      }
    },
    onRecaptchaExpired() {
      this.isCapchaValid = false;
      this.errors.captcha = 'CAPTCHA expired. Please try again.';
    },

    validateForm() {
      this.errors = {}; // Clear previous errors
      let isValid = true;

      // Validate email if isEmailValidate is true
     
        if (!this.formData.email) {
          this.errors.email = 'Email is required.';
          isValid = false;
        } else if (!this.isValidEmail(this.formData.email)) {
          this.errors.email = 'Invalid email format.';
          isValid = false;
        }
      
      // Validate first name
      if (!this.formData.firstName) {
        this.errors.firstName = 'First Name is required.';
        isValid = false;
      }

      // Validate last name
      if (!this.formData.lastName) {
        this.errors.lastName = 'Last Name is required.';
        isValid = false;
      }

      // Validate business name
      if (!this.formData.businessName) {
        this.errors.businessName = 'Business Name is required.';
        isValid = false;
      }

      // Validate message
      if (!this.formData.message) {
        this.errors.message = 'Message is required.';
        isValid = false;
      }

      return isValid;
    },

    isValidEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
    isValidPhone(phoneNo) {
      const re = /^[0-9]{10}$/;
      return re.test(phoneNo);
    },
    uploadForm(formData) {
      // eslint-disable-next-line
     // debugger;
       //  axios.post('https://localhost:7226/api/ContactForm/PostContactForm', formData, {
           axios.post('https://node.brytonprinting.com/api/ContactForm/PostContactForm', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => {
          this.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        }
      })
        .then(response => {
          this.$router.push('/Upload/ThankYou');
          console.log('Form submitted, response received:', response);
          if (response.data && response.data.contactId) {
            const contactId = response.data.contactId;
            console.log('Received Contact ID:', contactId);
          }

          this.resetForm();
        })
        .catch(error => {
          console.error('Error submitting form:', error);
          if (error.response && error.response.data && error.response.data.errors) {
            this.errors = error.response.data.errors;
          }
        });
    },
    handleDrop(event) {
      const files = event.dataTransfer.files;
      this.addFiles(files);
    },
    // handleFileSelection(event) {
    //   const files = event.target.files;
    //   this.addFiles(files);
    // },
    handleFileSelection(event) {
      const files = event.target.files;
      const maxSize = 2 * 1024 * 1024 * 1024; // 2 GB in bytes

      for (let i = 0; i < files.length; i++) {
        if (files[i].size > maxSize) {
          this.errors.files = 'File size exceeds the 2 GB limit.';
          return;
        }
        this.selectedFiles.push(files[i]);
      }
    },
    addFiles(files) {
      // eslint-disable-next-line
      debugger;
      for (let i = 0; i < files.length; i++) {
        this.selectedFiles.push(files[i]);
      }
      console.log('Files added:', this.selectedFiles);
    },
    removeFile(index) {
      this.selectedFiles.splice(index, 1);
      console.log('File removed:', index);
    },
    browseFiles() {
      this.$refs.fileInput.click();
    },
    resetForm() {
      this.formData = {
        name: '',
        companyName: '',
        message: '',
        email: '',
        phoneNo: ''
      };
      this.selectedFiles = [];
      this.errors = {};
      console.log('Form reset');
    },
    async GetEmailValidation() {
      // eslint-disable-next-line
      debugger;

      try {
       const response = await axios.get(`https://node.brytonprinting.com/api/ContactForm/GetEmailValidation`, {
       //    const response = await axios.get(`https://localhost:7226/api/ContactForm/GetEmailValidation`, {
          headers: {
            'Content-Type': 'application/json'
          },
        });

        // Check HTTP status code correctly
        if (response.status === 200) {
          // eslint-disable-next-line
          debugger;
          this.isEmailValidate = response.data[0].email;
          localStorage.setItem("userType", this.loginModel.username);
          this.$router.push('/UploadFileListing');
        } else {
          this.errorMessage = 'Invalid username or password.';
        }
      } catch (error) {
        this.errorMessage = 'An error occurred during login. Please try again.';
      }
    }
  }
};
</script>

<style scoped>
/* Add your component-specific styles here */
.flex-container[data-v-1208f910] {
  min-height: 50vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.tablet\:px-8 {
  padding-left: 0rem !important;
  padding-right: 2rem;
}

.contactform .text-input {
  width: 100% !important;
}

.text-danger {
  color: #ff0000;
}

.animated-underline.under--flex.animated-underline--left {
  margin: auto;
  margin-right: 51px;

}

.img-container {
  display: flex;
  justify-content: center;
  margin-right: 51px;
}

.animated-underline.under--flex.animated-underline--left[data-v-13b6e01b] {
  margin: auto;
  margin-left: 50px;
  margin-right: 51px;
  margin-bottom: 31px;
  margin-top: 25px;
}

@media (max-width:576px){
  .tablet\:px-8[data-v-13b6e01b] {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
}
}
</style>