<template>
  
    <div class="banner__spacer hidden tablet:block">
        <div class="fixed top-0 w-full z-10 bg-white">
            <!-- Logo and Contact Section -->
            <div class="page-container page-container--padded flex flex-row items-center justify-center">
                <!-- Logo -->
                <div class="mr-auto">
                    <a href="/" class="nuxt-link-active">
                        <img src="../assets/images/logo.png" alt="The Bryton Printing logo." width="358" height="47" class="banner__logo my-4" />
                    </a>
                </div>
                <!-- Contact Information -->
                <a href="tel:+1-714-993-4800" class="bolding-link font-semibold ml-4 whitespace-no-wrap text-sm desktop:text-lg">714-993-4800</a>
                <a href="mailto:Jeff@BrytonPrinting.com" class="bolding-link font-semibold whitespace-no-wrap text-sm desktop:text-lg ml-4">Jeff@BrytonPrinting.com</a>
            </div>

            <!-- Navigation Bar -->
            <!-- <nav class="border-t-2 border-b-2 border-gray-700 text-xs desktop:text-base">
      <ul role="menu" class="page-container flex flex-row justify-around tablet:px-0 font-semibold">
        <li role="none"><a href="PrintingServices" role="menuitem" class="nav__item bolding-link text-center">Commercial Printing Services</a></li>
        <li role="none"><a href="environment.html" role="menuitem" class="nav__item bolding-link text-center">Environment</a></li>
        <li role="none"><a href="/contactus" role="menuitem" class="nav__item bolding-link text-center">Contact</a></li>
        <li role="none"><a href="/uploadfile" role="menuitem" class="nav__item bolding-link text-center">Upload File</a></li>
      </ul>
    </nav>  -->
            <nav v-if="$route.name != 'Login' && $route.name != 'Pages' && $route.name != 'PagesList' && $route.name != 'FileList'" id="nav-login" class="border-t-2 border-b-2 border-gray-700 text-xs desktop:text-base text-align:center">
                <ul class="page-container flex flex-row justify-around tablet:px-0 font-semibold">
                    
                    <li id="menu-item-17633" class="nav__item bolding-link text-center" v-for="(link, index) in NavLinks[0]" :key="index">
                        <!--<router-link class="nav-link" :to="{ name: link.menuname,params:link.params}">{{link.displayText}}</router-link>-->
                        <router-link class="nav-link" :to="{ name:link.menuname,params:link.params}">{{link.displayText}} </router-link>
                        
                        <!--{{link.params}}-->
                    </li>
                    <!--<li v-for="(link, index) in NavLinks[0]" :key="index">
        <router-link class="nav__item bolding-link text-center"
             :to="{ name: link.menuname, state: { menuname: link.menuname } }" @click="menutoggle">{{ link.menuname }}</router-link>
    </li>-->

                    <!--<li><router-link to="/PrintingServices" class="nav__item bolding-link text-center">Commercial Printing Services</router-link></li>
                    <li><router-link to="/Enviorment" class="nav__item bolding-link text-center">Environment</router-link></li>-->
                    <li><router-link to="/contact" class="nav__item bolding-link text-center">Contact</router-link></li>
                    <li><router-link to="/UploadFiles" class="nav__item bolding-link text-center">Upload File</router-link></li>
                </ul>
            </nav>

        </div>
    </div>
    <router-view /> 
  </template>
  <script>
// import { Button } from 'bootstrap';
      //import AdminService from '../Services/AdminService'

  export default {
    name: "HeaderNavbar",
          data() {
              return {
                  NavLinks: '',
              }
          },
          /*props: ['HeaderNavlinks'],*/
          created() {
              this.NavLinks = (this.$router.options.HeaderNavlinks);
              console.log("this.NavLinks test", this.NavLinks);
              //this.GetDynamicMenu();
          },
          methods: {
              //async GetDynamicMenu() {
              //    // eslint-disable-next-line
              //    debugger;
              //    try {
              //        await AdminService.GetDynamicMenu(localStorage.getItem('companyId')).then((response) => {
              //            // eslint-disable-next-line
              //            debugger;
              //            this.NavLinks = response.data;
              //        });
              //    } catch (error) {
              //        console.error('Error fetching page content:', error);
              //    }
              //},
          },
    
  };
  </script>
  
  <style scoped>
/* General Styles */
.banner__spacer {
  padding-top: 1rem;
}


.fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.bg-white {
  background-color: white;
}

/* Container and Layout */
.page-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;

}

.page-container--padded {
  padding: 1rem 2rem;
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

/* Logo */
.banner__logo {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* Contact Links */
.bolding-link {
  color: #333;
  text-decoration: none;
  font-weight: 600;
}

.font-semibold {
  font-weight: 600;
}

.ml-4 {
  margin-left: 1rem;
}

.whitespace-no-wrap {
  white-space: nowrap;
}

.text-sm {
  font-size: 0.875rem;
}

.desktop\:text-lg {
  font-size: 1.125rem;
}

.text-xs {
  font-size: 0.75rem;
}

.desktop\:text-base {
  font-size: 1rem;
}

/* Navigation Bar */
nav {
  border-top: 2px solid #333;
  border-bottom: 2px solid #333;
  padding: 0.5rem 0;
}

.nav__item {
  color: #333;
  text-decoration: none;
  display: block;
  padding: 0.5rem 1rem;
  text-align: center;
}

.nav__item:hover {
  color: #007bff; /* Example hover color */
  text-decoration: underline;
}

/* List Styles */
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  display: inline;
}

/* Responsive Styles */
@media (min-width: 768px) {
  .hidden {
    display: none;
  }

  .tablet\:block {
    display: block;
  }

 
}

@media (min-width: 1024px) {
  .desktop\:text-lg {
    font-size: 1.25rem;
  }

  .desktop\:text-base {
    font-size: 1rem;
  }
}
</style>
