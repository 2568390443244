<template>
    <table width="100%" border="0" align="center" cellpadding="6" cellspacing="0">
        <tbody>
            <tr>
                <td height="30" colspan="3" align="CENTER" valign="middle" bgcolor="#cccccc" class="textbold"
                    style="padding-left: 5px;">
                    <strong style="text-align: center;margin: auto;display: flex;justify-content: center;align-items: center;">Add/Edit Page</strong>

                </td>
            </tr>
        </tbody>
    </table>
    <div class="container">
        <div class="col-lg-12">

            <div class="row search-box-row">

                <div class="col-md-12">
                    

                    
                    <div class="logout-main-boxes" style="text-align:right;">
                        <a href="/Pageslist" class="btn-logout nav__item bolding-link btn btn-primary text-center">
                            Goto Listsing Page
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <Button @click="logOut"
                                class="btn-logout nav__item bolding-link btn btn-primary text-center">
                            Logout
                        </Button>
                    </div>


                </div>
            </div>
        </div>
    </div>
    <Form @submit="uploadForm" class="form-container">
        <div class="row mb-3">
            <div class="form-group col-xl-6 col-lg-6 col-md-6">
                <label for="slug">Slug <span class="font-danger">*</span></label>
                <input type="text"
                       id="slug"
                       v-model="PagesModel.Slug"
                       name="Slug"
                       class="form-control"
                       maxlength="50"
                       placeholder="Enter Slug"
                       required />
            </div>
            <div class="form-group col-xl-6 col-lg-6 col-md-6">
                <label for="title">Page Title <span class="font-danger">*</span></label>
                <input type="text"
                       id="title"
                       v-model="PagesModel.Title"
                       name="Title"
                       class="form-control"
                       maxlength="100"
                       placeholder="Enter Page Title"
                       required />
            </div>
        </div>


        <div class="form-group col-md-12 col-sm-12">
            <label>Description:<span style="color:red"></span></label>
            <Editor v-model="PagesModel.description"
                    :api-key="$TNYCkEditorKey"
                    :init="{plugins: 'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons accordion',
                                        toolbar: 'undo redo | accordion accordionremove | blocks fontfamily fontsize | bold italic underline strikethrough | align numlist bullist | link image | table media | lineheight outdent indent| forecolor backcolor removeformat | charmap emoticons | code fullscreen preview',
                                        height:600,toolbar_mode: 'sliding' }" />
        </div>
        <br />
        <h3>Meta Information</h3>
        <br />
        <div class="row mb-3">
            <div class="form-group col-xl-6 col-lg-6 col-md-6">
                <label for="metaTitle">Title <span class="font-danger">*</span></label>
                <input type="text"
                       id="metaTitle"
                       v-model="PagesModel.metaTitle"
                       name="metaTitle"
                       class="form-control"
                       maxlength="100"
                       placeholder="Enter Title"
                       required />
            </div>
        </div>
        <div class="row mb-3">
            <div class="form-group col-xl-6 col-lg-6 col-md-6">
                <label for="metaKeyword">Meta Keyword <span class="font-danger">*</span></label>
                <textarea id="metaKeyword"
                          v-model="PagesModel.metaKeyword"
                          name="metaKeyword"
                          class="form-control"
                          rows="3"
                          maxlength="4000"
                          placeholder="Enter Meta Keyword"
                          required></textarea>
            </div>
            <div class="form-group col-xl-6 col-lg-6 col-md-6">
                <label for="metaDescription">Meta Description <span class="font-danger">*</span></label>
                <textarea id="metaDescription"
                          v-model="PagesModel.metaDescription"
                          name="metaDescription"
                          class="form-control"
                          rows="3"
                          maxlength="4000"
                          placeholder="Enter Meta Description"
                          required></textarea>
            </div>

        </div>

        <div class="form-group" style="visibility:hidden">
            <input type="checkbox" id="IsActive" name="IsActive" v-model="PagesModel.IsActive" />
            <label for="IsActive" class="ml-2">Is Active</label>
        </div>
        <div class="form-group">
            <input type="checkbox" id="headermenu" name="headermenu" v-model="PagesModel.headermenu" />
            <label for="headermenu" class="ml-2">Is Headermenu</label>
        </div>
        <br />
        <div class="input-group mb-3">
            <button type="submit" class="btn btn-success">Submit</button>
            <button @click="Cancel" type="button" class="btn btn-danger ml-2">Cancel and goto Listing</button>
        </div>
    </Form>
</template>

<script>
    //import axios from 'axios';
    import Editor from '@tinymce/tinymce-vue';
    import AdminService from '@/Services/AdminService';

    export default {
        components: {
            Editor,
        },
        name: 'SlugPages',
        data() {
            return {
                Id: null,
                PagesModel: {
                    Slug: '',
                    Title: '',
                    description: '',
                    metaTitle: '',
                    metaKeyword: '',
                    metaDescription: '',
                    headermenu: false,
                    IsActive: false,
                },
                errors: null,
            };
        },
        created() {
            if (localStorage.getItem('userType') == null) {
                this.$router.push('/Login');
            }
            this.$route.params.Id
            if (this.$route.params.Id != null || this.$route.params.Id != undefined) {
                this.Id = this.$route.params.Id;
                this.EditPages()
            }
        },
        methods: {
            async uploadForm() {
                // eslint-disable-next-line
                debugger;
                try {
                    const dataModel = {
                        Id: this.Id,
                        Slug: this.PagesModel.Slug,
                        Title: this.PagesModel.Title,
                        description: this.PagesModel.description,
                        metaTitle: this.PagesModel.metaTitle,
                        metaKeyword: this.PagesModel.metaKeyword,
                        metaDescription: this.PagesModel.metaDescription,
                        IsActive: this.PagesModel.IsActive,
                        headermenu: this.PagesModel.headermenu,
                    };

                    await AdminService.AddFormdata(dataModel).then((response) => {
                        console.log()
                        // eslint-disable-next-line
                        debugger;
                        if (response.data.statusCode == 200) {
                            alert(response.data.message);
                            this.$router.push('/pagesList');

                        }
                        if (response.data.statusCode == 400) {
                            alert(response.data.message);
                            this.$router.push('/pagesList');
                        }
                    });
                } catch (error) {
                    console.error('Error submitting form:', error);
                    if (error.response && error.response.data && error.response.data.errors) {
                        this.errors = error.response.data.errors;
                    }
                }
                this.$router.push('/pagesList');
            },

            //async uploadForm() {
            //    try {
            //        const response = await axios.post('https://localhost:7226/api/ContactForm/AddPages', this.PagesModel, {
            //            headers: {
            //                'Content-Type': 'application/json', // Changed to application/json
            //            },
            //        });

            //        alert("Data has been added successfully!");
            //        this.$toast.success("Data has been added successfully!");
            //        console.log('Form submitted, response received:', response);
            //        this.resetForm();
            //    } catch (error) {
            //        console.error('Error submitting form:', error);
            //        if (error.response && error.response.data && error.response.data.errors) {
            //            this.errors = error.response.data.errors;
            //        }
            //    }
            //},
            //resetForm() {
            //    this.PagesModel = {
            //        Slug: '',
            //        Title: '',
            //        description: '',
            //        metaTitle: '',
            //        metaKeyword: '',
            //        metaDescription: '',
            //        IsActive: false,
            //    };
            //    this.errors = null; // Clear errors
            //},
            EditPages() {
                // eslint-disable-next-line
                debugger;
                AdminService.EditPages(this.Id).then(response => {
                    // eslint-disable-next-line
                    debugger;
                    console.log("EditData", response);
                    this.PagesModel.Slug = response.data[0].Slug
                    this.PagesModel.Title = response.data[0].Title
                    this.PagesModel.description = response.data[0].Description
                    this.PagesModel.metaTitle = response.data[0].MetaTitle
                    this.PagesModel.metaKeyword = response.data[0].MetaKeyword
                    this.PagesModel.metaDescription = response.data[0].MetaDescription
                    this.PagesModel.IsActive = response.data[0].IsActive
                    this.PagesModel.headermenu = response.data[0].IsHeadermenu
                })
            },
            logOut() {
                localStorage.removeItem('userType');
                this.$router.push('/Login');
            },
            Cancel() {
                this.$router.push('/pagesList')
            }
        },
    };
</script>

<style scoped>
    .form-container {
        padding: 20px;
        background-color: #f9f9f9;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    .font-danger {
        color: red;
    }

    .input-group {
        display: flex;
        justify-content: flex-start;
    }

    .btn {
        margin-right: 10px;
    }
</style>
